import * as React from 'react'

import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout title="SEO - Search Engine Optimization">
      <h1>Search Engine Optimization (SEO)</h1>
      <p className="usa-intro">
        Finding your product in a very big sea of content.
      </p>
      <h2 id="bringyourusers">Bring your users to you</h2>
      <p className='measure-1'>
        <q>How do I check my eligibility for SNAP?</q> - A hypothetical Google Search by a Supplemental Nutrition Assistance Program (SNAP) Benefits recipient</p>
      <p>
        SEO can be the difference between a citizen of the United States getting SNAP benefits information 
        directly from a reliable .gov source and being directed to a personal blog, 
        unrelated non-profit, or shady stock broker for Snapchat stock.
      </p>
      <h3>Why does Google ignore certain websites?</h3>
      <p>
        Although it would be nice if Google, Bing, and other search engines could always bring users where they need to
        be, misdirection and misinformation are common on the internet.
        Applications need to implement SEO techniques to ensure that search engines both 
        understand the content within a website and prioritize it in search results.
      </p>
      <p>
        Written into the metadata of a webpage are special HyperText Markup Language (HTML) tags that only search engines read. These tags tell Google 
        what a website does, how search engines can interact with it, and informs Google's algorithm for directing users to it.
      </p>
      <p>
        Additionally, the companies behind search engines have strong opinions about web development as they are large development 
        firms themselves. They use the threat of a bad SEO score to push applications towards search and browser-friendly practices. 
        Benevolent overlords or not, failing to follow SEO conventions can remove your website from search results entirely. 
      </p>
      <h2 id="improvingseo">Improving SEO</h2>
      <p>Improving your SEO score is quite straight forward, as <a target="_blank" rel="noreferrer" className="usa-link" 
      href='https://developers.google.com/search/docs/beginner/seo-starter-guide'> search engines publish their own guides</a>.</p>
            
      <h3>Mobile devices</h3>
      <p>One component of an SEO score is how mobile-friendly a webpage is. Although as an organization, you may decide 
        that it is not a priority to develop for mobile devices, search engines have an interest in keeping your website 
        accessible for mobile device users. Adding tags such as <a target="_blank" rel="noreferrer" className="usa-link" 
        href='https://web.dev/viewport/'>the meta viewport tag</a> will bump your SEO score, and 
        make your website much more usable on mobile browsers.
      </p>
      <p>
        Another mobile-friendly practice that Google and other engines will measure is how well sized a website's touch targets are
        when the page is displayed on a touch screen device. If a target (the area around a link where a finger can land and trigger that link) 
        is less than 48 px by 48 px, or two touch target areas are less 
        than 8 px apart, the score considers this interactive button or link poorly designed.
      </p>
      <h3>Search metadata</h3>
      <p>Another piece of the SEO score relates to search engine metadata tags. The meta title and description are both displayed on search 
        results pages to users. Search engines will even determine how "high-quality" and "unique" your 
        description is and <a target="_blank" rel="noreferrer" className="usa-link" href='https://web.dev/meta-description/'>use 
        it to rank your website</a> in the results list.
      </p>
  </Layout>

  )
}

export default IndexPage
